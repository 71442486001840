<template>
  <b-sidebar
    ref="sidebar"
    shadow
    backdrop
    right
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    bg-variant="white"
    width="420px"
  >
    <template #header>
      <h3 class="my-1 mx-2 font-weight-bolder">
        {{ header }}
      </h3>
    </template>
    <validation-observer
      ref="rules"
      tag="form"
      class="px-3 mt-2"
      @submit.prevent="submit"
    >
      <section v-if="customer">
        <b-form-group :label="$t('Management.Customers.Name')" label-for="name">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Customers.Name')"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="customer.name"
              :placeholder="
                $t('Global.Write', { subject: $t('Management.Customers.Name') })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Management.Customers.Street')"
          label-for="street"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Customers.Street')"
            rules="required"
          >
            <b-form-input
              id="street"
              v-model="customer.street"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Customers.Street'),
                })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('Management.Customers.Zip')" label-for="zip">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Customers.Zip')"
            rules="required"
          >
            <b-form-input
              id="zip"
              v-model="customer.zip"
              :placeholder="
                $t('Global.Write', { subject: $t('Management.Customers.Zip') })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('Management.Customers.City')" label-for="city">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Customers.City')"
            rules="required"
          >
            <b-form-input
              id="city"
              v-model="customer.city"
              :placeholder="
                $t('Global.Write', { subject: $t('Management.Customers.City') })
              "
              :state="errors.length > 0 ? false : null"
              :disabled="!can"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Management.Customers.Country')"
          label-for="country"
        >
          <div class="select-container">
            <div class="country-select">
              <v-select
                id="country"
                v-model="customer.country"
                :placeholder="
                  $t('Global.Select', {
                    subject: $t('Management.Customers.Country'),
                  })
                "
                :options="countries"
                :disabled="!can"
                :label="label"
                @option:selected="countrySelected"
              >
              <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
                <template #option="{ name }">
                  <div class="d-flex">
                    {{ name }}
                  </div>
                </template>
                <template #selected-option="{ name }">
                  <div>{{ name }}</div>
                </template>
                
              </v-select>
            </div>
          </div>
        </b-form-group>
      </section>
    </validation-observer>
    <template #footer>
      <div class="d-flex justify-content-between m-2">
        <b-button
          v-if="can"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="submit"
        >
          {{ $t("Global.Save") }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="sidebarVisible = false"
        >
          {{ $t("Global.Retreat") }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import vSelect from "vue-select";
import { BButton, BSidebar, BFormInput, BFormGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { preventUnsavedChangesPopupLevel } from "@/utility/mixins/preventUnsavedChanges";

export default {
  components: {
    BButton,
    BSidebar,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  mixins: [preventUnsavedChangesPopupLevel],

  props: {
    customer: {
      type: Object,
      default: () => null,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    can: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    required,
    label: "name",
    countryOptions: [],
  }),

  methods: {
    countrySelected(selectedOption){
      if(selectedOption.id === "0"){
        this.customer.country = null;
        this.$emit("openCountryModal");
      }
    },

    reset() {
      this.$emit("reset");
      this.$refs.rules.reset();
    },

    async submit() {
      const valid = await this.$refs.rules.validate();

      if (valid) {
        this.$emit("submit");
        return;
      }
      this.$alert.invalid();
    },
  },
};
</script>
